import React from "react"

const CTAImage = ({ image }) => {
  return (
    <div className="col-lg-6 col-xs-12 zero-padding">
      <div className="working-img src-work text-center">
        <div className="zoom">
          <img className="img-fluid" src={image.src} alt="cta" />
        </div>
      </div>
    </div>
  )
}

export default CTAImage
