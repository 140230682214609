import React from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby"

const HOST = process.env.GATSBY_HOST

const CTABody = ({ title, description, url, urlName, isRight }) => {
  return (
    <div className="col-lg-6 col-xs-12 zero-padding">
      <div
        className={
          isRight ? "working-content working-content-right" : "working-content"
        }
      >
        <h2>{title}</h2>
        <p>{description}</p>
        {urlName && (
          <button
            className="theme-btn"
            onClick={() => {
              const newURL = url.includes(HOST)
              if (newURL) {
                navigate(`/${url.split(HOST)[1]}`)
              } else {
                window.location.href = url
              }
            }}
          >
            {urlName}
          </button>
        )}
      </div>
    </div>
  )
}

CTABody.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  urlName: PropTypes.string,
}

CTABody.defaultProps = {
  isRight: false,
}

export default CTABody
