import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

import Layout from "../../components/layout"
import CTABlock from "../../components/blocks/cta_block"

const ClubSimplePage = ({ data }) => {
  const page = data.wagtail.page
  let _ancestor = { children: [], button: {} }
  data.wagtail.page.ancestors.forEach((item) => {
    if ("children" in item) {
      _ancestor = item
    }
  })

  return (
    <Layout
      isContent={true}
      subMenu={_ancestor.children}
      button={_ancestor.button}
      parent={"clubs"}
      siteTitle={page.title}
    >
      <section className="breadcrumb-area breadcrumb-sub-area">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb nav-breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/clubs">Clubs</Link>
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="sport-safety-area">
        <div className="tab-content-area">
          <div className="container simple-page">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="col-12">
                    <h1>{page.title}</h1>
                    <p>{page.descriptionSimple}</p>
                    {page.feedImage && (
                      <div className="clib-area">
                        <div className="club-img full-width-image">
                          <img
                            src={data.wagtail.page.feedImage.src}
                            alt="swim"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div
                      className="c-title-content mb-80"
                      dangerouslySetInnerHTML={{
                        __html: data.wagtail.page.bodySafe,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {data.wagtail.page.fullContent &&
        data.wagtail.page.fullContent.length > 0 && (
          <CTABlock data={data.wagtail.page.fullContent[0]}></CTABlock>
        )}
    </Layout>
  )
}

ClubSimplePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const query = graphql`
  query ($id: Int) {
    wagtail {
      page(id: $id) {
        ... on SimplePage {
          id
          bodySafe
          descriptionSimple
          feedImage {
            src
          }
          button {
            name
            text
            url
          }
          ancestors {
            ... on ClubsPage {
              children {
                title
                slug
                ... on SimplePage {
                  live
                  isProtected
                }
                ... on FindClub {
                  live
                  isProtected
                }
              }
              button {
                text
                name
                url
              }
            }
          }

          title
          fullContent {
            ... on CTABlock {
              id
              title
              image {
                ... on ImageObjectType {
                  src
                  file
                  width
                  height
                }
              }
              description
              orientation
              url
              urlName
            }
          }
        }
      }
    }
  }
`
export default ClubSimplePage
