import React from "react"

import CTABody from "./cta_body"
import CTAImage from "./cta_image"

const CTABlock = ({ data }) => {
  if (!data) {
    return null
  }
  return (
    <section
      className={
        data.orientation === 2
          ? "working-area working-area-right src-area"
          : "working-area src-area"
      }
    >
      <div className="container">
        <div className="row working-sub-area">
          <div className="col-lg-12 col-xs-12 d-flex flex-column-reverse flex-xs-column flex-lg-row align-items-center">
            {data.orientation === 2 && (
              <>
                <CTAImage image={data.image} />
                <CTABody
                  title={data.title}
                  description={data.description}
                  url={data.url}
                  urlName={data.urlName}
                  isRight={true}
                />
              </>
            )}
            {data.orientation === 1 && (
              <>
                <CTABody
                  title={data.title}
                  description={data.description}
                  url={data.url}
                  urlName={data.urlName}
                />
                <CTAImage image={data.image} />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default CTABlock
